.modal-wrapper
{
    margin: 20px 10px;
    padding: 15px 10px;
    background-color: rgba(255, 255, 0, 0.281);
    border-radius: 5px;
    border: dotted 1px yellow;
}

.modal-wrapper p
{
    margin: 0;
}