.verify-cont {
  margin: auto 5vh;
  padding: 100px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  top: 20%;
  right: 50%;
  transform: translateX(50%);
}

.email-watermark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 60%;
  right: 50%;
  transform: translate(50%);
  filter: grayscale(1);
  opacity: 0.15;
  z-index: -1;
  transition: 0.5s;
  animation: fade 0.5s ease-in-out;
}
.email-watermark img {
  height: 200px;
  width: fit-content;
  margin: 5px auto 20px;
}
div.msg-container {
  text-align: center;
}
p.msg {
  font-size: 1.3rem;
  margin-top: 0;
  padding-top: 0%;
}
p.success-msg {
  color: chartreuse;
}

p.warning-msg {
  color: rgb(248, 66, 66);
}

button.redirect-btn {
  display: block;
  margin: auto;
  padding: 8px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: rgb(243, 233, 140);
  border: none;
  border-radius: 2px;
  font-weight: 600;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}
