@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
:root
{
    --bodycolor: rgb(27, 27, 27);
    --textcolor:rgb(248, 248, 248);
    --yellowcolor: rgb(243, 233, 140);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a
{
    text-decoration: none;
    color: rgb(248, 248, 248);
    color: var(--textcolor);
}
a:hover{
  -webkit-text-decoration: 1px underline;
          text-decoration: 1px underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body
{
    font-family: 'Roboto', sans-serif;;
}
:root {
  --bodycolor: rgb(27, 27, 27);
  --textcolor: rgb(248, 248, 248);
}

body {
  /* max-height: 100vh; */
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: rgb(27, 27, 27);
  background-color: var(--bodycolor);
  color: rgb(248, 248, 248);
  color: var(--textcolor);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.landing-container {
  width: 90vw;
  max-width: 1160px;
  display: flex;
  flex-direction: row;
  height: 80vh;
  position: relative;
  margin-top: 10vh;
}
.lbox {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.rbox {
  margin-left: 0px;
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.left,
.right {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
}

svg.stack {
  max-width: 250px;
  width: 15vw;
  min-width: 200px;
  align-self: center;
}

.text {
  align-self: center;
  margin: auto;
  width: -webkit-max-content;
  width: max-content;
  padding: 20px;
}
.notefy-heading {
  font-size: 8vw;
  /* 7em  */
  margin: 0px;
  color: rgb(248, 248, 248);
  color: var(--textcolor);
}

.tagline {
  align-self: center;
  font-size: 2.3em;
  font-size: 2.5vw;
  font-weight: 600;
  color: rgb(248, 248, 248);
  color: var(--textcolor);
  margin: 10px 0;
  letter-spacing: 1px;
}
.crafted {
  margin: 30px 0;
  padding: 20px 0;
  font-size: 1.3em;
  word-spacing: 2px;
}

.crafted span {
  font-weight: 700;
  font-size: 1.25em;
}
.githublogo {
  height: 0.9em;
  margin: 0 8px;
  fill: rgb(248, 248, 248);
  fill: var(--textcolor);
}

.rbox img {
  width: 70%;
  margin: 30px;
}
img.wednesday {
  width: 60%;
}

@media screen and (max-width: 480px) {
  .lbox {
    width: 100%;
  }
  .rbox {
    width: 100%;
    flex-direction: column;
  }
  .landing-container {
    flex-wrap: wrap;
    width: auto !important;
    /* max-width: 1160px; */
    display: flex;
    flex-direction: row;
    height: auto;
    position: relative;
    margin-top: 10vh;
  }
  .left {
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
  }
  .img.wednesday {
    width: 50%;
  }
  .rbox img {
    width: 50%;
  }
  .color-image,
  .notes-image {
    width: 70% !important;
  }
  .notefy-heading {
    font-size: 12vw;
  }
  .crafted {
    font-size: 1rem;
  }
}

.fa-moon
{
    padding: 5px;
    font-size: 1.4em;
    position: absolute;
    right: 0;
    cursor: pointer;
    border-radius: 1.4em;
    transition: 0.3s;
    color: var(--textcolor);
}

.fa-moon:hover
{
    transition: 0.3s;
    background-color: var(--textcolor);
    color: var(--bodycolor);
}



.form-wrapper {
  margin: 10vh auto 5vh;
  padding: 100px 40px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.login,
.register {
  margin: 0 20px;
  padding: 0px 20px;
}

.divider {
  width: 3px;
  background-color: rgb(243, 233, 140);
  align-self: stretch;
}

.form-wrapper h1 {
  font-size: 3rem;
  margin: 0;
  color: var(--textcolor);
}

.form-wrapper p {
  font-size: 1.3rem;
}
.form-wrapper a {
  color: rgb(243, 233, 140);
  cursor: pointer;
  font-weight: 600;
}

label,
input {
  display: block;
  margin: 5px 0;
}

.form-wrapper label {
  color: var(--textcolor);
  font-weight: 600;
}

.form-wrapper input {
  background-color: rgba(139, 137, 137, 0.39);
  border-radius: 5px;
  border: none;
  /* border-bottom: 2px solid var(--textcolor); */
  padding: 8px 10px;
  font-size: 1.2rem;
  color: var(--textcolor);
  margin: 10px 0 20px;
  width: 90%;
  min-width: 200px;
}
input:focus {
  outline: none;
}

.form-wrapper button {
  display: block;
  margin: 20px 0px;
  padding: 8px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: rgb(243, 233, 140);
  border: none;
  border-radius: 2px;
  font-weight: 600;
}

.registerBtn {
  margin-top: 60px !important;
}
.form-wrapper button:hover {
  background-color: #f5e44f;
}

.display-none {
  display: none;
}

.cover {
  position: absolute;
  /* width: calc(55% - 40px); */
  height: calc(100% - 200px);
  -webkit-transform: translate(-120px, 0);
          transform: translate(-120px, 0);
  transition: 0.3s cubic-bezier(0.66, 0.01, 0, 1.75);
  z-index: 1;
  align-self: flex-start;
}

.cover-register {
  -webkit-transform: translate(82%, 0%);
          transform: translate(82%, 0%);
  transition: 0.3s cubic-bezier(0.66, 0.01, 0, 1.75);
}

.warning {
  color: rgb(248, 66, 66);
  position: absolute;
  display: block;
}

.login-warning {
  position: static;
  margin: 20px 0;
}

.success {
  color: chartreuse;
}

.error {
  margin-top: -8px;
}

.lds-ring {
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin-top: -8px;
  }
.stoneLoader
{
  margin-left: 10px;
}
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 3px;
    border: 3px solid #f5e44f;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #f5e44f transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
.modal-wrapper
{
    margin: 20px 10px;
    padding: 15px 10px;
    background-color: rgba(255, 255, 0, 0.281);
    border-radius: 5px;
    border: dotted 1px yellow;
}

.modal-wrapper p
{
    margin: 0;
}
.Auth_AuthFormContainer__37Wqn{
    margin: 200px auto;
    padding: 24px 40px;
    width: 368px;
    text-align: center;
    box-shadow: 0px 0px 64px rgba(10, 10, 10, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #191C1E;
} 

.Auth_AuthFormContainer__37Wqn h3{
    font-size: 24px; 
    color:#fff; 
}

/* .SocialSignin{
    margin-top: 20px;
    text-align: center;

}
.google{
    width: 40%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    background-color: red;
} */
/* .para{
    color: #fff;
    text-align: left;
} */

.Auth_Link__1SKw2{
    display: block;
    margin-top: 16px; 
}
.Button_Button__7EBH5{
    cursor: pointer;
    padding: 8px 24px; 
    color: #ffffff;
    border: none;
    border-radius: 10px; 
    font-family: 'Montserrat';
    height: 100%;

}

.Button_Button__7EBH5:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    background: linear-gradient(to right, #FFF 50%, #d8dc1b 90%, #FFF 100%);
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* text-fill-color: transparent; */
    
    -webkit-animation: Button_waves__1Ik-Y 1.5s forwards ease-out;
    
            animation: Button_waves__1Ik-Y 1.5s forwards ease-out;
}

@-webkit-keyframes Button_waves__1Ik-Y {
    to {
      background-position: 200% center;
    }
  }

@keyframes Button_waves__1Ik-Y {
    to {
      background-position: 200% center;
    }
  }

.Button_Active__3-RSy {
    background: #2357DD;    
}

.Button_Danger__3GItt {
    background: #EE675C;
}

.Button_Parent__1fDkb {
    display: inline-block; 
    height: 40px; 
    border-radius: 10px; 
}
.Input_InputWrapper__skuNx{
    margin: 24px 0px;    
}

.Input_InputGroup__1qn4o {
    position: relative;
    display: flex; 
    align-items: center;


}
.Input_Input__2ybY_{
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border-radius: 4px;
    background-color: #000000;
    border-style: none;
    height: 40px; 
    color: #fff; 
    padding-left: 40px; 
    font-size: 14px; 
}

.Input_InputGroup__1qn4o span{
    position: absolute;
    left: 8px; 
}
.Input_Input__2ybY_::-webkit-input-placeholder{
    color: #7e7e7e;
    font-size: 14px; 

}
.Input_Input__2ybY_:-ms-input-placeholder{
    color: #7e7e7e;
    font-size: 14px; 

}
.Input_Input__2ybY_::placeholder{
    color: #7e7e7e;
    font-size: 14px; 

}

.Input_Input__2ybY_:focus{
    border: 1px solid #2357DD;
    box-shadow: 0px 4px 4px rgba(35, 87, 221, 0.15);
    width: 100%;
}

.Input_Label__1NQMU{
    float: left;
    margin: 8px 0px;
    font-size: 14px; 
}

.Input_InputError__3jL04:focus{
    border: 1px solid #FF5E5E;
    box-shadow: 0px 4px 4px rgba(255, 94, 94, 0.15);
}
.verify-cont {
  margin: auto 5vh;
  padding: 100px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  top: 20%;
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.email-watermark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 60%;
  right: 50%;
  -webkit-transform: translate(50%);
          transform: translate(50%);
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.15;
  z-index: -1;
  transition: 0.5s;
  -webkit-animation: fade 0.5s ease-in-out;
          animation: fade 0.5s ease-in-out;
}
.email-watermark img {
  height: 200px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px auto 20px;
}
div.msg-container {
  text-align: center;
}
p.msg {
  font-size: 1.3rem;
  margin-top: 0;
  padding-top: 0%;
}
p.success-msg {
  color: chartreuse;
}

p.warning-msg {
  color: rgb(248, 66, 66);
}

button.redirect-btn {
  display: block;
  margin: auto;
  padding: 8px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: rgb(243, 233, 140);
  border: none;
  border-radius: 2px;
  font-weight: 600;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}

.searchBar
{
    width: 95%;
    padding: 10px 10px;
    margin: 20px auto 30px;
    background-color: rgba(83, 83, 83, 0.788);
    border-radius: 10px;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-icon
{
    align-self: center;
    justify-self: flex-start;
    margin: 5px 10px;
    height: 100%;
    fill: white;
}

.searchinput
{
    background-color: transparent;
    border: none;
    width: 100%;
    margin: 5px 10px;
    margin-left: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: white;
}

.searchinput::-webkit-input-placeholder
{
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.644);
}

.searchinput:-ms-input-placeholder
{
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.644);
}

.searchinput::placeholder
{
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.644);
}

.searchinput:focus
{
    outline: none;
}
.note {
  background-color: rgb(243, 233, 140);
  color: rgb(27, 27, 27);
  padding: 1rem;
  padding-bottom: 0px;
  border-radius: 7px;
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 4px 4px 2px rgb(128, 127, 127);
  -webkit-animation: appear 0.3s ease-in-out;
          animation: appear 0.3s ease-in-out;
  transition: 0.2s;
  scrollbar-base-color: transparent;
  scrollbar-width: none; /* Firefox 64 */
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
.noteText {
  overflow: scroll;
  height: 100%;
  scrollbar-width: none; /* Firefox 64 */
}
.note p {
  margin: 16px 0 6px;
}

.note-effects:hover {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}

.addnote {
  background-color: rgb(255, 217, 223);
}

.note-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  scrollbar-width: none; /* Firefox 64 */
}

small {
  opacity: 0.7;
}

.notes-list {
  align-self: flex-start;
  width: 98%;
  padding: 1%;
  display: grid;
  grid-gap: 1.2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  max-height: 100%;
  border-radius: 10px;
  /* overflow: scroll; */
  scrollbar-width: none; /* Firefox 64 */
}

.addnote textarea,
.expanded textarea {
  background-color: transparent;
  border: none;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  margin: 16px 0 6px;
  resize: none;
}

.expanded textarea {
  width: calc(100% - 16px);
  height: 95%;
  padding: 0;
}
.addnote textarea:focus,
.expanded textarea:focus {
  border: none;
  outline: none;
}

.saveBtn {
  cursor: pointer;
  background-color: rgba(59, 59, 59, 0.829);
  color: rgb(248, 248, 248);
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 1rem;
  border: none;
}
.saveBtn:hover {
  background-color: rgb(27, 27, 27);
}

.delete-icon {
  cursor: pointer;
  transition: 0.3s;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.delete-icon:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  transition: 0.3s;
}
.edit-icon {
  margin: 0 10px;
  cursor: pointer;
  transition: 0.3s;
}
.edit-icon:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  transition: 0.3s;
}

.deleted {
  -webkit-animation: disappear 0.3s ease-in-out;
          animation: disappear 0.3s ease-in-out;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.expanded {
  position: absolute;
  width: 70%;
  height: 70%;
  max-height: none;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding-bottom: 10px;
  z-index: 2;
  -webkit-animation: 0.5s appear2;
          animation: 0.5s appear2;
  box-shadow: 0px 0px 100px 1000px rgba(0, 0, 0, 0.568);
}

.close-icon {
  cursor: pointer;
  transition: 0.2s;
}
.close-icon:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  transition: 0.2s;
}

.edit-icon-expanded {
  cursor: pointer;
  transition: 0.3s;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.edit-icon-expanded:hover {
  transition: 0.3s;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

/* if you ever want to change the p tags with textareas  */

/* .note-text-area-small {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  background: transparent;
  border: none;
  resize: none;
  width: 100%;
  height: 100%;
}

.note-text-area-small:focus {
  outline: none;
} */

.note-p-textspace {
  white-space: pre-wrap;
}

@-webkit-keyframes appear {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes appear {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes appear2 {
  0% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%);
  }
  100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes appear2 {
  0% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%);
  }
  100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%);
  }
}
@-webkit-keyframes disappear {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes disappear {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@media all and (max-width: 350px) {
  .note {
    width: 250px;
  }
  .notes-list {
    width: 98%;
    padding: 1%;
    display: grid;
    grid-gap: 1.2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.dashboard {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  align-items: center;
}
.nav-menu{
  display: none;
}
.side-nav {
  width: 20%;
  min-width: 250px;
  height: 93%;
  margin-right: 10px;
  padding: 20px 30px;
  padding-left: 50px;
  /* border-right: 1px dashed var(--textcolor); */
}

.cont {
  align-self: flex-start;
  padding: 20px;
  margin-right: 20px;
  width: 75%;
  display: flex;
  flex-direction: column;
  max-height: 92%;
  border-radius: 10px;
  overflow: scroll;
  scrollbar-width: none; /* Firefox 64 */
}

.watermark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 70%;
  right: 40%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.15;
  z-index: -1;
  transition: 0.5s;
  -webkit-animation: fade 0.5s ease-in-out;
          animation: fade 0.5s ease-in-out;
}

.watermark img {
  height: 200px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px auto 20px;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}

@media all and (max-width: 800px){
  .side-nav-open{
    min-width: 100%;
    height: 93%;
    margin-right: 10px;
    padding: 20px 30px;
    padding-left: 50px;
  }
  .side-nav{
    display: none;
  }
  .cont {
    margin: auto;
    width: 85%;
  }
  .nav-menu{
    display: block;
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  .nav-menu-icon{
    height: 70%;
    width: 70%;
  }
  .dashboard{
    overflow-x: hidden;
  }
}

@media all and (max-width: 350px){
  .side-nav{
    width: 20%;
    min-width: 250px;
    height: 93%;
    margin-right: 10px;
    padding: 20px 30px;
    padding-left: 50px;
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 800px){
  .cont{
    margin-top: 0;
  }
}


.sidenav
{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 100%;
    position: relative;
}

.navhead
{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
}

.heading
{
    
    font-size: 3em;
    margin: 0px;
    color: var(--textcolor);
    padding: 7px;
}

.sidenav h4
{
    margin: 10px 0;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 73%;
    min-width: 250px;
}
.icon
{
    margin: 0px 10px 0px 0px;
}
.navlogo
{
    height: 50px;
    margin: 0px 5px;
}
.sidenav h4:hover
{
    background-color: var(--textcolor);
    color: var(--bodycolor);
    opacity: 0.7;
}
.sidenav h4.active
{
    background-color: var(--textcolor);
    color: var(--bodycolor);
}

.sidenav a{
    cursor: pointer;
    text-decoration: none;
}

.sidenavIcons
{
    position: absolute;
    bottom: 0;
    left: 0;
    margin-right: auto;
    width: 100%;
    margin-top: auto;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.sidenav-moon
{
    position:static;
}

.delete-all
{
    padding: 4px;
    font-size: 1.7em;
    margin: 0px 10px;
    margin-left: 30px;
    cursor: pointer;
    transition: 0.3s;
}

.delete-all:hover
{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    transition: 0.3s;
}

@media all and (max-height: 600px){
    .sidenavIcons
    {
        margin-bottom: 90px;
    }
}

@media (min-height: 600px) and (max-height: 650px){
    .sidenavIcons
    {
        margin-bottom: 150px;
    }
}



.settings
{
    display: flex;
    flex-direction: column;
}
.settingsHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}


.userInfo h1
{
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.userInfo span
{
    opacity: 0.7;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.avatar
{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
}

.pfpContainer
{
    height: 100px;
    width: 100px;
    border-radius: 100px;
    margin: 0 auto;
    overflow: hidden;
   text-align: right;
}
.pfp
{
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
}

.changeAvatar
{
    margin-top: 10px;
    font-size: 0.8rem;
    cursor: pointer;
}

.changeAvatar:hover
{
    text-decoration: underline;
    color: var(--yellowcolor);
}

.controlContainer
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0px 20px;
    width: 75%;
    padding: 10px 30px;
    border: 2px dotted rgba(255, 255, 0, 0.589);
    border-radius: 10px;
}
.option
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.option input
{
    margin: 0px 10px;
}

.settingsForm
{
    padding: 0;
    margin: 0;
    margin-top: 0px;
}

.settingsForm form
{
    width: 50%;
}
.catGif
{
    opacity: 0.6;
    height: 60vh;
    width: 80vh;
    -webkit-filter: invert();
            filter: invert();
}

.passbox
{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icondiv
{
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.changepassBtn
{
    margin-top: 50px !important;
}
