.dashboard {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  align-items: center;
}
.nav-menu{
  display: none;
}
.side-nav {
  width: 20%;
  min-width: 250px;
  height: 93%;
  margin-right: 10px;
  padding: 20px 30px;
  padding-left: 50px;
  /* border-right: 1px dashed var(--textcolor); */
}

.cont {
  align-self: flex-start;
  padding: 20px;
  margin-right: 20px;
  width: 75%;
  display: flex;
  flex-direction: column;
  max-height: 92%;
  border-radius: 10px;
  overflow: scroll;
  scrollbar-width: none; /* Firefox 64 */
}

.watermark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 70%;
  right: 40%;
  transform: translate(50%, -50%);
  filter: grayscale(1);
  opacity: 0.15;
  z-index: -1;
  transition: 0.5s;
  animation: fade 0.5s ease-in-out;
}

.watermark img {
  height: 200px;
  width: fit-content;
  margin: 5px auto 20px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}

@media all and (max-width: 800px){
  .side-nav-open{
    min-width: 100%;
    height: 93%;
    margin-right: 10px;
    padding: 20px 30px;
    padding-left: 50px;
  }
  .side-nav{
    display: none;
  }
  .cont {
    margin: auto;
    width: 85%;
  }
  .nav-menu{
    display: block;
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  .nav-menu-icon{
    height: 70%;
    width: 70%;
  }
  .dashboard{
    overflow-x: hidden;
  }
}

@media all and (max-width: 350px){
  .side-nav{
    width: 20%;
    min-width: 250px;
    height: 93%;
    margin-right: 10px;
    padding: 20px 30px;
    padding-left: 50px;
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 800px){
  .cont{
    margin-top: 0;
  }
}

