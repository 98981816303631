.AuthFormContainer{
    margin: 200px auto;
    padding: 24px 40px;
    width: 368px;
    text-align: center;
    box-shadow: 0px 0px 64px rgba(10, 10, 10, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #191C1E;
} 

.AuthFormContainer h3{
    font-size: 24px; 
    color:#fff; 
}

/* .SocialSignin{
    margin-top: 20px;
    text-align: center;

}
.google{
    width: 40%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    background-color: red;
} */
/* .para{
    color: #fff;
    text-align: left;
} */

.Link{
    display: block;
    margin-top: 16px; 
}